import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Waypoint } from 'react-waypoint';
import { window } from 'browser-monads';

const nsBase = 'component';
const ns = `${ nsBase }-reveal`;

const Reveal = ({ children, config, callback }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true
	});

	// settings composed from config props/default props
	const settings = {
		bottomOffset: config.bottomOffset || Reveal.defaultProps.config.bottomOffset,
		initialStyles: config.initialStyles || Reveal.defaultProps.config.initialStyles
	};

	// target
	const targetElement = useRef();

	// reveal state
	const [revealed, setRevealed] = useState(false);

	/**
	 *  Responsible for setting revealed state
	 *  to true or false
	*/
	const handleReveal = ({ currentPosition }) => {
		if (currentPosition === 'above' || currentPosition === 'inside') {
			setRevealed(true);
		}
	};

	/**
	 * Runs callback passed as prop with target element and
	 * revealed state for animation manipulation
	*/
	useEffect(() => {
		callback(targetElement.current, revealed);
	}, [revealed, callback]);

	return (
		<Waypoint
			onEnter={handleReveal}
			onLeave={handleReveal}
			bottomOffset={settings.bottomOffset}
			scrollableAncestor={window}
		>
			<div
				ref={targetElement}
				className={rootClassnames}
				data-revealed={revealed}
				style={settings.initialStyles}
			>
				{children}
			</div>
		</Waypoint>
	);
};

Reveal.defaultProps = {
	callback: (target, revealed) => {
		const elem = target;

		elem.style.opacity = (revealed === true ? 1 : 0);
		// elem.style.transform = (revealed === true ? 'translateY(0)' : 'translateY(80px)');
	},
	config: {
		initialStyles: {
			transition: 'all 0.4s ease',
			transformStyle: 'preserve-3d',
			opacity: 0
			// transform: 'translateY(80px)'
		},
		bottomOffset: '100px'
	}
};

export default Reveal;
