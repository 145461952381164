import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Rellax from 'rellax';

import Button from '../Button';
import Reveal from '../Reveal';

const nsBase = 'component';
const ns = `${ nsBase }-hero`;

const Hero = (props) => {
	const {
		children,
		variant,
		heading,
		pageTitle,
		anchors,
		subcopy,
		description,
		cta
	} = props;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }__${ variant }`]: variant
	});

	const heroReveal = {
		config: {
			initialStyles: {
				transition: 'all 1.5s cubic-bezier(0.35, 1, 0.45, 1) 1s',
				// transitionDelay: '1.25s',
				transformStyle: 'preserve-3d',
				opacity: 0,
				transform: 'translateY(80px)'
			}
		},
		callback: (target, revealed) => {
			const elem = target;

			elem.style.opacity = (revealed === true ? 1 : 0);
			elem.style.transform = (revealed === true ? 'translateY(0)' : 'translateY(80px)');
		}
	};

	// target
	const targetElement = useRef();

	useEffect(() => {
		const rellax = new Rellax(targetElement.current); //eslint-disable-line
	}, []);

	return (
		<div className={rootClassnames}>
			<Reveal
				callback={heroReveal.callback}
				config={heroReveal.config}
			>
				<div className={'container-fluid'}>
					{heading && (
						<h1
							ref={targetElement}
							className={`${ ns }__heading`}
							data-rellax-speed={'2'}
						>
							{heading}
						</h1>
					)}
					<div className={`${ ns }__content`}>
						{children ? (
							<>
								{children}
							</>
						) : (
							<>
								<div className={`${ ns }__content-left`}>
									{pageTitle && (
										<div className={`${ ns }__page-title`}>
											Envoy Ventures
											<br />
											{pageTitle}
										</div>
									)}
								</div>
								<div className={`${ ns }__content-right`}>
									{anchors && (
										<div className={`${ ns }__anchors`}>
											{anchors.map((anchor) => {
												return (
													<div
														key={uniqueId('envoy')}
														className={`${ ns }__anchor`}
													>
														<AnchorLink
															href={anchor.anchor}
															className={`component-link component-link--underline`}
														>
															{anchor.text}
														</AnchorLink>
													</div>
												);
											})}
										</div>
									)}
									{subcopy && (
										<div className={`${ ns }__subcopy`}>{subcopy}</div>
									)}
									{description && (
										<div className={`${ ns }__description`}>{description}</div>
									)}
									{cta && (
										<Button
											className={`${ ns }__cta`}
											href={cta.url}
											variant={'white'}
										>
											{cta.label}
										</Button>
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</Reveal>
		</div>
	);
};

export default Hero;
