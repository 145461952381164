/* eslint-disable camelcase */
import React from 'react';
import classNames from 'classnames';

const nsBase = 'component';
const ns = `${ nsBase }-image`;

const Image = (props) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true
	});

	const {
		alt_text,
		main_image,
		tablet_image,
		laptop_image,
		desktop_image,
		widescreen_image
	} = props;

	// if no main_image return nothing
	if (!main_image) return null;

	let tabletSet = null;
	let laptopSet = null;
	let desktopSet = null;
	let widescreenSet = null;

	if (tablet_image) {
		tabletSet = <source media={`(min-width: 768px)`} srcSet={tablet_image} />;
	}

	if (laptop_image) {
		laptopSet = <source media={`(min-width: 992px)`} srcSet={laptop_image} />;
	}

	if (desktop_image) {
		desktopSet = <source media={`(min-width: 1200px)`} srcSet={desktop_image} />;
	}

	if (widescreen_image) {
		widescreenSet = <source media={`(min-width: 1400px)`} srcSet={widescreen_image} />;
	}

	return (
		<picture>
			{widescreenSet}
			{desktopSet}
			{laptopSet}
			{tabletSet}
			<img className={rootClassnames} alt={alt_text} src={main_image} />
		</picture>
	);
};

export default Image;
